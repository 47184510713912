import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";

/**
 * 查询是否已收藏
 */
/**
 */
 export async function getIsFav(params) {
  return request(
    addurl("getIsFav", requestApiUrl.requestFavoritedata) + '/' + params.username  + '/' + params.doi + '/' + params.restype,
    METHOD.get
  );
}
/**
 * 浏览记录
 */
/**
 */
 export async function addLog(params) {
  request(
   addurl("AddLog", requestApiUrl.requestLog),
   METHOD.POST,params
 );
}
/**
 * 收藏/取消收藏
 */
/**
 */
 export async function addFavoriteData(params) {
  return request(
    addurl("AddFavoriteData", requestApiUrl.requestFavoritedata),
    METHOD.POST,params
  );
}

/**
 * 获取视频列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getNewsList(params) {
  return request(
    addurl("getList", requestApiUrl.requestVideo) +
      "/" +
      params.page +
      "/" +
      params.pageSize,
    METHOD.GET,
    {
      query: params.Keyword,
      online: 1,
      typeid: 0,
      type: "p",
    }
  );
}
/**
 * 获取详情
 * @param {*} doi
 * @returns
 */
export async function getInfo(doi) {
  return request(
    addurl("getInfo", requestApiUrl.requestVideo) + "/" + doi,
    METHOD.GET,
    {
      type: "p",
    }
  );
}

/**
 * 获取根据推荐
 * @param {*} doi
 * @returns
 */
export async function getRecommendByType(params) {
  return request(
    addurl("getRecommendResourcesList", requestApiUrl.requestRecommend) + "/" + params.page+'/'+params.pageSize,
    METHOD.GET,{
      type:params.type
    }
  );
}
