<template>
  <div>
    <div class="nav-ul">
      <ul id="myTabs" class="nav-tabs clearfix">
        <li
          v-for="(item, index) in titleList"
          :key="index"
          @click="selectItem(item)"
        >
          <a :class="{ activeClass: item.active }">{{ item.name }}</a>
        </li>
      </ul>
    </div>
    <div class="content-wrap my-content">
      <div class="tab-content">
        <div class="tab-info">
          <!-- 视频 -->
          <div class="con-item vidio tabVidio" v-if="type === 'vidio'">
            <div
              class="item-container"
              v-for="(item, index) in vidioList"
              :key="index"
            >
              <div class="row clearfix">
                <div
                  class="col-xs-12"
                  @click="goRouter('MobileVideoDetail', item)"
                >
                  <div class="pic">
                    <img :src="item.sys_fld_coverpath" alt="" />
                    <span></span>
                  </div>
                  <h5 @click="goRouter('MobileVideoDetail', item)">
                    <a>{{ item.name }}</a>
                  </h5>
                  <p>{{ item.source }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 听书 -->
          <div class="con-item audio tabAudio" v-if="type === 'audio'">
            <div
              class="item-container"
              v-for="(item, index) in audioList"
              :key="index"
            >
              <div class="row clearfix">
                <div
                  class="col-xs-4"
                  @click="goRouter('MobileAudioDetail', item)"
                >
                  <div class="pic">
                    <img :src="item.sys_fld_coverpath" alt="" />
                    <span></span>
                  </div>
                </div>
                <div
                  class="col-xs-8"
                  @click="goRouter('MobileAudioDetail', item)"
                >
                  <div class="keys-text">
                    <h4>{{ item.name }}</h4>
                    <p class="brief">{{ item.source }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 图书 -->
          <div class="con-item book tabBook" v-if="type === 'book'">
            <div
              class="item-container"
              v-for="(item, index) in bookList"
              :key="index"
              @click="goRouter('MobileBookDetail', item)"
            >
              <div class="row clearfix">
                <div class="col-xs-4">
                  <a href="#">
                    <img :src="item.sys_fld_coverpath" alt="" />
                  </a>
                </div>
                <div class="col-xs-8">
                  <div class="keys-text">
                    <div class="txt">
                      <h4>{{ item.name }}</h4>
                      <!-- <p class="brief">
                      {{ item.digest }}
                    </p> -->
                      <p class="brief">{{ item.author }} 著</p>
                    </div>
                    <div class="author">
                      <!-- <span>{{ item.author }}</span>
                    著<br/> -->
                      <span>
                        {{ item.issuedep }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 图书 -->
          <div class="con-item book" v-if="type === 'remond'">
            <div class="item-container">
              <div
                class="row clearfix"
                @click="goRouter('MobileBookDetail', recommendBookList)"
              >
                <div class="col-xs-4">
                  <a>
                    <img :src="recommendBookList.pictruepath" alt="" />
                  </a>
                </div>
                <div class="col-xs-8">
                  <div class="keys-text">
                    <div class="txt">
                      <h4>{{ recommendBookList.name }}</h4>
                      <p class="brief">
                        {{ recommendBookList.digest }}
                      </p>
                    </div>
                    <div class="author">
                      <span>{{ recommendBookList.author }}</span>
                      著
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 视频 -->
          <div class="con-item vidio" v-if="type === 'remond'">
            <div class="item-container">
              <div class="row clearfix">
                <div
                  class="col-xs-12"
                  @click="goRouter('MobileVideoDetail', recommendVidioList)"
                >
                  <div class="pic">
                    <img :src="recommendVidioList.imgurl" alt="" />
                    <span></span>
                  </div>
                  <h5>
                    <a>{{ recommendVidioList.name }}</a>
                  </h5>
                  <p>{{ recommendVidioList.source }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- 音频 -->
          <div class="con-item audio" v-if="type === 'remond'">
            <div class="item-container">
              <div
                class="row clearfix"
                @click="goRouter('MobileAudioDetail', recommendAudioList)"
              >
                <div class="col-xs-4">
                  <div class="pic">
                    <img :src="recommendAudioList.imgurl" alt="" />
                    <span></span>
                  </div>
                </div>
                <div
                  class="col-xs-8"
                  @click="goRouter('MobileAudioDetail', recommendAudioList)"
                >
                  <div class="keys-text">
                    <h4>{{ recommendAudioList.name }}</h4>
                    <p class="brief">{{ recommendAudioList.source }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 新闻资讯 -->
          <div class="con-item news" v-if="type === 'remond'">
            <div class="con-title">
              <div class="item-container">
                <div class="row clearfix">
                  <div class="col-xs-12">
                    <h4>
                      <i></i>
                      新闻资讯
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-list">
              <div class="item-container">
                <ul class="clearfix">
                  <li
                    v-for="(item, index) in newsList"
                    :key="index"
                    @click="goDetail('MobileNewsDetail', item, '1')"
                  >
                    <div class="row clearfix">
                      <div class="col-xs-4">
                        <a><img :src="item.sys_fld_coverpath" /></a>
                      </div>
                      <div class="col-xs-8">
                        <div class="keys-text">
                          <h4>
                            {{ item.title }}
                          </h4>
                          <p>{{ item.pubdate }}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 活动预告 -->
          <div class="con-item activityMobile" v-if="type === 'remond'">
            <div class="con-title">
              <div class="item-container">
                <div class="row clearfix">
                  <div class="col-xs-12">
                    <h4>
                      <i></i>
                      活动预告
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="activity-list">
              <div class="item-container clearfix">
                <div class="row clearfix">
                  <div class="col-xs-12">
                    <ul>
                      <li
                        v-for="(item, index) in activityList"
                        :key="index"
                        @click="goDetail('MobileNewsDetail', item, '0')"
                      >
                        <h4>
                          <a>{{ item.title }}</a>
                        </h4>
                        <p>{{ item.pubdate }}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeApi from "@/services/home";
import * as Api from "@/services/video";
import * as audioApi from "@/services/audio";
import { replacePath, isMobile, silceTime } from "@/utils/util";
import { recommendDoiList } from "@/config/constConfig";
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Index",
  //   components: { upcomingEvents, DigitalResources, News },
  data() {
    return {
      recommendBookList: {}, //推荐图书
      recommendVidioList: {}, //推荐视频
      recommendAudioList: {}, //推荐音频
      newsList: [], //新闻资讯列表信息
      activityList: [], //活动列表信息
      type: "remond",
      titleList: [
        { name: "推荐", active: true, type: "remond" },
        { name: "图书", active: false, type: "book" },
        { name: "听书", active: false, type: "audio" },
        { name: "视频", active: false, type: "vidio" },
      ],
      vidioList: [],
      audioList: [],
      bookList: [],
    };
  },
  computed: {
    ...mapGetters("account", ["user"],
    //this.bindActive(),
    ),
  },
  // computed(){
  //   this.bindActive()},
  created() {
    this.bindActive();
    this.Mobile();
    //获取推荐图书
    this.getRecommendedBooks();
    //获取推荐视频
    this.getRecommendedVidio();
    //获取推荐音频
    this.getRecommendedAudio();
    //获取新闻资讯列表
    this.getNewsList();
    //获取活动信息列表
    this.getActivityList();
    //获取图书列表
    this.getBookList();
    //获取音频列表
    this.getAudioList();
    //获取视频列表
    this.getvideoList();
  },
  methods: {
    ...mapMutations("account", ["saveNowRouter"]),
    Mobile() {
      if (!isMobile()) {
        this.$router.push({ name: "index" });
      }
    },
    bindActive() {
      this.$emit("bindActive", "mobileIndex");
    },
    goDetail(name, item, type) {
      
      this.$router.push({
        name: name,
        params: {
          doi: item.urlid,
          type: type,
        },
      });
    },
    //路由跳转
    goRouter(name, item) {
      if(name==='MobileVideoDetail'||name==='MobileAudioDetail'){
        var vm = this;
      if (!this.user || this.user === "") {
        this.$confirm({
          title: "确认提示",
          content: `登录后即可查看，是否确认登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      if (
        localStorage.getItem("expireAt") &&
        new Date().getTime() >=
          new Date(localStorage.getItem("expireAt")).getTime()
      ) {
        this.$confirm({
          title: "确认提示",
          content: `登录已过期，该操作需重新登录，是否重新登录？`,
          okType: "warning",
          onOk() {
            vm.saveNowRouter(vm.$route);
            vm.$router.push({ name: "Login" });
          },
          onCancel() {
            console.log("取消");
          },
        });
        return;
      }
      }
      this.$router.push({
        name: name,
        params: {
          doi: item.sys_fld_doi,
        },
      });
    },
    selectItem(item) {
      this.titleList.map(function (data) {
        data.active = false;
      });
      item.active = true;
      this.type = item.type;
    },
    //获取推荐图书
    getRecommendedBooks() {
      let obj = {
        // typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        typeDoi: "666c6b27-8626-4027-a300-914a6eb16c43",
        page: 1,
        size: 5,
      };
      homeApi.getRecommendList(obj).then((res) => {
        if (res.success) {
          this.recommendBookList = res.data.list[0];
          this.recommendBookList.pictruepath = replacePath(
            this.recommendBookList.sys_fld_coverpath
          );
        } else {
          this.recommendBookList = {};
        }
      });
    },
    //获取推荐视频
    getRecommendedVidio() {
      Api.getRecommendByType({
        page: 1,
        pageSize: 4,
        type: recommendDoiList.detailVideoDoi,
      }).then((res) => {
        const { success } = res;
        if (success) {
          const {
            data: { list },
          } = res;
          this.recommendVidioList = list[2];
          this.recommendVidioList.imgurl = replacePath(
            this.recommendVidioList.sys_fld_coverpath,
            "0",
            "1"
          );
        } else {
          this.recommendVidioList = {};
        }
      });
    },
    //获取音频
    getRecommendedAudio() {
      audioApi
        .getRecommendByType({
          page: 1,
          pageSize: 4,
          type: recommendDoiList.detailAudioDoi,
        })
        .then((res) => {
          const { success } = res;
          if (success) {
            const {
              data: { list },
            } = res;
            this.recommendAudioList = list[2];
            this.recommendAudioList.imgurl = replacePath(
              this.recommendAudioList.sys_fld_coverpath,
              "0",
              "1"
            );
          } else {
            this.recommendAudioList = {};
          }
        });
    },
    //获取新闻资讯列表
    getNewsList() {
      let obj = {
        page: 1,
        size: 3,
        typeid: "1",
      };
      homeApi.getNewsList(obj).then((res) => {
        if (res.success) {
          this.newsList = res.data.list;
          this.newsList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
            item.pubdate = silceTime(item.pubdate);
          });
        } else {
          this.newsList = [];
        }
      });
    },
    //获取活动信息列表
    getActivityList() {
      let obj = {
        page: 1,
        size: 6,
        typeid: "0",
      };
      homeApi.getNewsList(obj).then((res) => {
        if (res.success) {
          this.activityList = res.data.list;
          this.activityList.map((item) => {
            item.pubdate = silceTime(item.pubdate);
          });
        } else {
          this.activityList = [];
        }
      });
    },
    //获取图书列表
    getBookList() {
      let obj = {
        page: 1,
        size: 10,
      };
      homeApi.getBookList(obj).then((res) => {
        if (res.success) {
          this.bookList = res.data.list;
          this.bookList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
        } else {
          this.bookList = [];
        }
      });
    },
    //获取音频列表
    getAudioList() {
      let obj = {
        page: 1,
        size: 10,
        order: "SYS_FLD_HITCOUNT",
      };
      homeApi.getAudioList(obj).then((res) => {
        if (res.success) {
          this.audioList = res.data.list;
          this.audioList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
        } else {
          this.audioList = [];
        }
      });
    },
    //获取视频列表
    getvideoList() {
      let obj = {
        page: 1,
        size: 10,
      };
      homeApi.getvideoList(obj).then((res) => {
        if (res.success) {
          this.vidioList = res.data.list;
          this.vidioList.map((item) => {
            item.sys_fld_coverpath = replacePath(item.sys_fld_coverpath);
          });
        } else {
          this.vidioList = [];
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "~@/views/mobileHome/index.less";
</style>
